import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Subscribtion from './Subscribtion'

const SubscribRouting = () => {
  return (
    <Routes>
        <Route path={"all"} element={<Subscribtion/>}></Route>
    </Routes>
  )
}

export default SubscribRouting