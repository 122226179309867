import React from 'react'
import Scheduler from './calender/Schedular'

const Calendar = () => {
  return (
    <div>
        <Scheduler/>
    </div>
  )
}

export default Calendar