import React from 'react'

const Demo = () => {
  return (
    <div className='px-2'>
    <h2>Video Demo</h2>
    <video width="800" controls>
      <source src={"https://www.w3schools.com/html/mov_bbb.mp4"} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/7lFAh_BR3_E?si=G6X10-Br1n9JhPp-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
  </div>
  )
}

export default Demo