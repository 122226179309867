import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import { icons } from './ShareComp';

const Policy = () => {
  useEffect(()=>{
    //window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" });
    window.scrollTo({ top: 0, behavior: "smooth" });
  },[])
  return (
    <div>
      {/* <NavLink to={"/dashboard"} className={"d-flex align-items-center gap-2 btn bg-light col-md-1 border position-fixed none mt-2 mx-2"}>{icons.backArrow}Back</NavLink> */}
      <div className="privacy-container">
    <div className="privacy-header">
      <i>Privacy Policy</i>
    </div>
    <p className="ppolicy">
      <strong>BLUHEALTH</strong> is committed to protecting the privacy of our users. This Privacy Policy outlines the types of personal information we collect when you use our mobile application ("BLUHEALTH"), how we use and safeguard that information, and your choices regarding your data.
    </p>
    <section className="psection">
      <h2 className="ph2">1. Information We Collect</h2>
      {/* <p className="ppolicy">
       
        <strong>BLUHEALTH</strong> We may collect personal information such as your name, email address, and other contact details when you voluntarily provide them to us.
      </p> */}
      <div className="info-section">
        <h3>Personal Information</h3>
        <p className="ppolicy">
        We may collect personal information such as your name, email address, and other contact information when you voluntarily provide it to us.
        </p>
      </div>
      <div className="info-section">
        <h3>Health Data</h3>
        <p className="ppolicy">
        Our app utilizes remote Photoplethysmography (rPPG) technology to gather health vitals or biomarkers. This may include heart rate, blood oxygen levels, and other relevant health metrics.
        </p>
      </div>
      <div className="info-section">
        <h3>Usage Information</h3>
        <p className="ppolicy">
        We may collect information about your interactions with the app, including features you use, pages you visit, and the frequency and duration of your activities.
        </p>
      </div>
    </section>
    <section className="psection">
      <h2 className="ph2">2. Use of Information</h2>
      <p className="ppolicy">
      We may use the collected information for the following purposes:
      </p>
      <div className="info-section">
        <p className="ppolicy">
        To provide and improve our services, including personalized health insights and risk scoring based on the collected health data.
        </p>
      </div>
      <div className="info-section">
        <p className="ppolicy">
        To communicate with you, respond to inquiries, and offer customer support.
        </p>
      </div>
      <div className="info-section">
        <p className="ppolicy">
        To analyze trends, monitor usage patterns, and enhance the overall user experience
        </p>
      </div>
    </section>
    <section className="psection">
      <h2 className="ph2">3. Data Accuracy and Disclaimer</h2>
      <p className="ppolicy">
      While we strive to provide accurate health information and insights, please note the following:
      </p>
      <div className="info-section">
        <p className="ppolicy">
        The information provided by the app is intended for informational purposes only and should not be considered medical advice or diagnosis.
        </p>
      </div>
      <div className="info-section">
        <p className="ppolicy">
        We do not guarantee the accuracy, completeness, or reliability of the health information provided by the app.
        </p>
      </div>
      <div className="info-section">
        <p className="ppolicy">
        Users should consult a registered healthcare practitioner before making any healthcare decisions or changes based on the app's information.
        </p>
      </div>
    </section>
    <section className="psection">
      <h2 className="ph2">4. Data Security</h2>
      <p className="ppolicy">
      We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.
      </p>
    </section>
    <section className="psection">
      <h2 className="ph2">5. Third-Party Services</h2>
      <p className="ppolicy">
      Our app may integrate with third-party services, such as analytics providers or healthcare partners. These third parties may collect information about your use of the app, subject to their own privacy policies.
      </p>
    </section>
    <section className="psection">
      <h2 className="ph2">6. Face Data Collection and Use</h2>
      <div className="info-section">
        <h3>Collection and Use</h3>
        <p className="ppolicy">
        As part of using the app, including any connected devices (e.g., fitness straps), certain health-related data may be collected and processed, such as:
        <li>Blood pressure</li>
        <li>Heart Rate (BPM)</li>
        <li>Respiration (RPM)</li>
        <li>Stress level</li>
        <li>HRV-SDNN (ms)</li>
        <li>Calculated biomarkers and the "Bluhealth Wellness Score" to track and maintain historical measurement data.</li>
        </p>
        <p className="ppolicy">
        The app uses video of a small patch of skin from your face or finger (without identifiable features such as eyes). This video is processed locally on your device in real-time, is not retained once the measurement ends, and is not transferred to our servers.
        </p>
      </div>
      <div className="info-section">
        <h3>Disclosure and Sharing</h3>
        <p className="ppolicy">
        In some cases, vital signs and health-related data are transmitted directly from your device to the relevant account owner. We do not retain or process such data.
        </p>
        <p className="ppolicy">
        We may share personal data with third-party service providers that help us provide and improve our services.
        </p>
      </div>
      <div className="info-section">
        <h3>Retention</h3>
        <p className="ppolicy">
        We retain the personal data we collect for as long as necessary for the purposes outlined above, under applicable regulations, or until you choose to opt out, where applicable.  </p>
      </div>
    </section>
    <section className="psection">
      <h2 className="ph2">7. Changes to Privacy Policy</h2>
      <p className="ppolicy">
      We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the updated Privacy Policy within the app.
      </p>
    </section>
    <section className="psection">
      <h2 className="ph2">8. Contact Us</h2>
      <p className="ppolicy">
      If you have any questions or concerns about our Privacy Policy or data practices, please contact us at developer@support@ibluhealth.com.
      </p>
      <p className="ppolicy">
      By using our app, you consent to the collection and use of your information as described in this Privacy Policy.
      </p>
    </section>
  </div>
    </div>
  )
}

export default Policy