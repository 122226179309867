import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { icons } from '../../components/ShareComp'
import ReactTableShare from '../../components/ReactTableShare'
import User from "../../assets/images/user.jpg"
import CreateAppointment from './CreateAppointment'
import Select from "react-select"
import moment from 'moment'
import { UseFormValidations } from '../../validations/UseFormValidation'
const AppointmentList = (props) => {
  const [open,setOpen]=useState(false)
  const [search,setSearch]=useState("")
  const [list,setList]=useState([])
  const {data,handleChangeSearch}=UseFormValidations({})
  const columns = [
    {name:"Id",selector:(v)=>v?.appointmentId,width:"3rem"},
   
    {
      name: "Name",
      selector: (v) =><div className='text-wrap'>{v?.patientName}</div> ,
      sortable: true,
      width: "10rem",
    },
    { name: "Email", selector: (v) => <div className='text-wrap'>{v?.email}</div>, sortable: true, width: "12rem" },
    
    {
      name: "Hospital",
      selector: (v) =><div className='text-wrap'>{v?.hospitalName}</div> ,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Date&Time",
      selector: (v) => <div>
        <div className=''>{v?.preferredDate}</div>
        <div style={{fontSize:"12px",fontWeight:"500"}}>{`[${v?.preferredTime}]`}</div>
        </div>,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Doctor",
      selector: (v) =><div>
        <div>{v?.clinicianName}</div>
        <div style={{fontSize:"12px",fontWeight:"500"}}>{`[${v?.specialityName}]`}</div>
        </div>,
       
      sortable: true,
      width: "10rem",
    },
    {
      name: "Type",
      selector: (v) =>v?.type,
       
      sortable: true,
     
    },
    {
      name: "Status",
      selector: (v) =><div>
        <div>{v?.status}</div>
        
        </div>,
       
      sortable: true,
      width: "",
    },
    // {
    //   name: "Action",
    //   selector: (v) => (
    //     <div className="ptr d-flex gap-2">
    //       <span onClick={() => ("user", v)}>{icons?.edit}</span>
          
    //       <span onClick={() => ("speciality", v)}>
    //         {icons?.assignSpecility}
    //       </span>
    //     </div>
    //   ),
    //   sortable: true,
    //   width: "",
    // },
  ];

  const handleChang = () =>(e)=> {
    setSearch(e.target.value)
  }
  useEffect(()=>{ 
       
    setList(props?.list)
   
  },[props?.list])
  useEffect(()=>{
    if(data?.hospitalId?.value){
      let res=props?.list.filter((v)=>v.hospitalId==data?.hospitalId?.value)
      console?.log(res,"res")
      setList(res)
    }
    else{
      setList(props?.list)
    }
   
  },[data?.hospitalId?.value])
  

  const listsearch = () => {
    return (
      <div className=" col-md-12 d-flex flex-wrap gap-1" >
        <div className="col-6">
          <input
            type="search"
            className="form-control search-control search-bg "
            value={search!=""?search:""}
            onChange={handleChang()}
            placeholder="Search..."
          />
        </div>
        <div className="col">
          <Select
           styles={{zIndex:"1"}}
            isClearable={true}
            options={props?.hospital}
            placeholder={"Select Hospital"}
            className="text-start index"
            name="facility"
            onChange={handleChangeSearch("hospitalId")}
          />
        </div>
        {/* <div className="col">
          <Select
           
            isClearable={true}
            options={props?.doctor}
            placeholder={"Select Doctor"}
            className="text-start"
            name="facility"
            onChange={handleChangeSearch("userId")}
          />
        </div> */}
      </div>
    );
  };

 

  return (
    <div>
      <div className="d-flex flex-wrap py-1">
        <div className="col-md-6 px-2 heading_list ">Appointment</div>
        <div className="col-md-6 d-flex flex-wrap gap-2 justify-content-end">
        
        {/* <div
            className="btn bg_btn col-auto text-white d-flex gap-2 align-items-center"
            onClick={show}
          >
            {icons.add}
            <span className="color2" >Add Appointment</span>
          </div> */}
          
         
        </div>
      </div>
      <div className="py-2 " >
        <ReactTableShare
          dataTable={list||[]}
          columns={columns || []}
          search1={listsearch}
          search={search}
        />
        {open&&<CreateAppointment show={open} onHide={()=>setOpen(false)}/>}
      </div>
      
    </div>
  )
}

export default AppointmentList